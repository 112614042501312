import { Grid, Heading, Link, Text, VStack } from '@chakra-ui/react';
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { type FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WireGuardConfigAutomaticReload } from './WireGuardConfigAutomaticReload';
import { AccountDataField, InputContainer, SectionError } from 'components';
import {
  WireGuardDeploymentStatus,
  type WireGuardConfig,
  type WireGuardQuery,
} from 'utils/graphql/hooks';
import { routes } from 'utils/routes';

export interface WireGuardConfigInfoProps {
  wireGuardConfigs: WireGuardConfig[];
  hasActiveSubscription: boolean;
  refetchConfig: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<WireGuardQuery, Error>>;
}

export const WireGuardConfigInfo: FunctionComponent<WireGuardConfigInfoProps> = ({
  wireGuardConfigs,
  hasActiveSubscription,
  refetchConfig,
}) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="normal" color={!hasActiveSubscription ? 'blackAlpha.500' : 'inherit'}>
      <Heading fontSize="lg">
        {t(`config.wireGuard.peerConfigHeader${hasActiveSubscription ? '' : 'Disabled'}`)}
      </Heading>
      <Text>{t('config.wireGuard.peerConfigDescription')}</Text>
      {wireGuardConfigs.map((config) => (
        <Grid key={config.uuid} mt={4}>
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Deploying && (
            <WireGuardConfigAutomaticReload configId={config.uuid} refetchConfig={refetchConfig} />
          )}
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Failed && (
            <SectionError title={t('config.wireGuard.error.configFailed')}>
              <Text>
                <Trans i18nKey="config.wireGuard.error.configFailedMessage">
                  <Link
                    href={routes.account.support}
                    textDecoration="underline"
                    variant="hoverBrand"
                  />
                </Trans>
              </Text>
            </SectionError>
          )}
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Done && (
            <>
              <AccountDataField
                title={t('config.wireGuard.clientIp')}
                data={config.status.clientIp}
                mx="0"
              />
              <AccountDataField
                title={t('config.wireGuard.publicKey')}
                data={config.spec.clientPublicKey}
                mx="0"
              />
              <AccountDataField
                title={t('config.wireGuard.endpoint')}
                data={config.status.wgUrl}
                mx="0"
              />
              <InputContainer mx="0">
                <Text pb="2">{t('config.wireGuard.peerConfigTempl')}</Text>
                <Text
                  px="4"
                  py="1"
                  w="full"
                  border="1px"
                  borderColor="qGray.100"
                  borderRadius="base"
                  fontWeight="bold"
                  whiteSpace="pre"
                  data-testid="peer-config-template"
                >{`[Interface]\nPrivateKey = YOUR_CLIENT_PRIVATE_KEY\nListenPort = 51820\nAddress = ${config.status.clientIp}/32\n\n[Peer]\nPublicKey = ${config.status.wgPublicKey}\nAllowedIPs = 10.90.0.0/16\nEndpoint = ${config.status.wgUrl}:51820\nPersistentKeepalive = 25`}</Text>
              </InputContainer>
            </>
          )}
        </Grid>
      ))}
    </VStack>
  );
};
