import { Text, VStack, Heading } from '@chakra-ui/react';
import { useMemo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreInfoText } from '../components/MoreInfoText';
import {
  WireGuardConfigForm,
  WireGuardConfigInfo,
  WireGuardSubscriptionSection,
  activeSubscriptionStates,
} from './components';
import { WireGuardConfigPagePlaceholder } from './WireGuardConfigPagePlaceholder';
import { SectionError, SectionLoading, SectionStack } from 'components';
import { UserGroup, useWireGuardQuery } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { routes } from 'utils/routes';

export const WireGuardConfigurationPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hasRequiredRole } = useAuthUserContext();

  const isWireGuardEnabled = useMemo(
    () =>
      process.env.REACT_APP_TOGGLE_WIREGUARD_PAGE === 'true' ||
      hasRequiredRole(UserGroup.SystemAdmin),
    [hasRequiredRole],
  );

  const { data, isPending, isError, error, refetch } = useWireGuardQuery(
    {},
    { enabled: isWireGuardEnabled },
  );

  const hasActiveSubscription = useMemo(() => {
    const subscription = data?.wireGuard.subscription;

    if (!subscription) {
      return false;
    }

    return activeSubscriptionStates.includes(subscription.state);
  }, [data?.wireGuard.subscription]);

  if (!isWireGuardEnabled) {
    return <WireGuardConfigPagePlaceholder />;
  }

  return (
    <VStack alignItems="stretch">
      <SectionStack type="outer" px={[5, 10]} py={[4, 7]} spacing="7">
        <VStack alignItems="normal">
          <Heading as="h1" size="lg">
            {t('config.wireGuard.heading')}
          </Heading>
          <Text>{t('config.wireGuard.description')}</Text>
          <MoreInfoText link={routes.external.devHub.wireGuard} />
        </VStack>
        {isPending && <SectionLoading />}
        {isError && <SectionError title={t('config.error.query')} message={error.message} />}
        {!isPending && !isError && (
          <WireGuardSubscriptionSection subscription={data.wireGuard.subscription} />
        )}
        {data?.wireGuard.configs?.length && (
          <WireGuardConfigInfo
            wireGuardConfigs={data?.wireGuard.configs}
            hasActiveSubscription={hasActiveSubscription}
            refetchConfig={refetch}
          />
        )}
        {!isPending &&
          !isError &&
          !data?.wireGuard.configs?.length &&
          data.wireGuard.subscription &&
          hasActiveSubscription && (
            <WireGuardConfigForm
              refetchConfig={refetch}
              instanceType={data.wireGuard.subscription.instanceType}
            />
          )}
      </SectionStack>
    </VStack>
  );
};
