import { Box, Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { type FunctionComponent, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WireGuardInstanceTypeSelection } from './WireGuardInstanceTypeSelection';
import { AccountDataField } from 'components';
import { formatToDateTimeString } from 'utils/dateUtils';
import {
  ShopProductSku,
  SubscriptionState,
  WireGuardInstanceType,
  type WireGuardSubscription,
} from 'utils/graphql/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';

export const activeSubscriptionStates = [SubscriptionState.Active, SubscriptionState.Canceled];

interface WireGuardSubscriptionSectionProps {
  subscription?: WireGuardSubscription | null;
}

export const WireGuardSubscriptionSection: FunctionComponent<WireGuardSubscriptionSectionProps> = ({
  subscription,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();
  const { setOrderItems, prefetchShopCheckoutPageData } = useShopCheckoutContext();
  const navigate = useNavigate();

  const [instanceType, setInstanceType] = useState<WireGuardInstanceType>(
    WireGuardInstanceType.Small,
  );

  const instanceTypeSku = useMemo(() => {
    switch (instanceType) {
      case WireGuardInstanceType.Small:
        return ShopProductSku.WireguardBase_1m;
      case WireGuardInstanceType.Xlarge:
        return ShopProductSku.WireguardXl_1m;
      default:
        throw new Error(
          `Could not find a shop product that matches WireGuard instance type: ${instanceType}`,
        );
    }
  }, [instanceType]);

  const prefetchCheckoutData = useCallback(() => {
    prefetchShopCheckoutPageData(instanceTypeSku);
  }, [instanceTypeSku, prefetchShopCheckoutPageData]);

  return (
    <VStack alignItems="normal">
      <Heading fontSize="lg">{t('config.wireGuard.subscription.sectionTitle')}</Heading>
      {subscription ? (
        <Box>
          <AccountDataField
            title={t('common.state')}
            data={t(`config.wireGuard.subscription.states.${subscription.state}`)}
            mx="0"
          />
          <AccountDataField
            title={t('config.wireGuard.instanceType')}
            data={t(`config.wireGuard.instanceTypes.${subscription.instanceType}`)}
            mx="0"
          />
          {subscription.endDate && (
            <AccountDataField
              title={t('config.wireGuard.subscription.endDate')}
              data={formatToDateTimeString(subscription.endDate, true)}
              mx="0"
            />
          )}
        </Box>
      ) : (
        <>
          <Text>{t('config.wireGuard.subscription.noSubscriptionInfo')}</Text>

          <WireGuardInstanceTypeSelection
            wireGuardInstance={instanceType}
            setWireGuardInstance={setInstanceType}
            my="4"
          />

          <Text>{t('config.wireGuard.instanceTypes.description')}</Text>

          <Text>{t('config.wireGuard.subscription.getSubscriptionInfo')}</Text>

          <Center>
            <Button
              mt="4"
              onMouseEnter={prefetchCheckoutData}
              onFocus={prefetchCheckoutData}
              onClick={() => {
                prefetchShopCheckoutPageData(instanceTypeSku);
                setOrderItems([{ sku: instanceTypeSku, quantity: 1 }]);
                navigate(routes.shopCheckout);
              }}
              {...readonlyProps}
            >
              {t('config.wireGuard.subscription.goToCheckout')}
            </Button>
          </Center>
        </>
      )}
    </VStack>
  );
};
